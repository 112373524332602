<template>
  <div>
    <ButtonSpinner
      id="1"
      :class="styles"
      :is-loading="isLoading"
      @click.native="
        (stripePriceId === 'price_1IqHIsI4HEbZGFYDbTvrgK5P' ||
          stripePriceId === 'price_1LBHT4I4HEbZGFYDs3FwKCZ9' ||
          stripePriceId === 'price_1LG1J4I4HEbZGFYDPtfeGWDV') &&
        currentProperty.propertyType === 1
          ? chooseTitleDeed()
          : pay('house')
      "
    >
      Objednat
    </ButtonSpinner>
    <Modal v-if="isTitleDeed" @chooseType="modalOptionHandler" />
    <ModalError v-if="errorModalOpen" @close="errorModalOpen = false" />

    <stripe-checkout
      ref="checkoutRef"
      mode="payment"
      locale="cs"
      :pk="pk"
      :line-items="lineItems"
      :success-url="successUrl"
      :cancel-url="cancelUrl"
      :clientReferenceId="clientReferenceId"
      :customerEmail="customerEmail"
      :metadata="metadata"
      @loading="(v) => (loading = v)"
    />
  </div>
</template>

<script>
import ButtonSpinner from "vue-button-spinner";
import { StripeCheckout } from "@vue-stripe/vue-stripe";
import { LOGIN } from "@/store/user/constants";
import { mapState, mapActions } from "vuex";
import { generateHouseReport } from "../../services/rest/services";
import Modal from "../modals/PropertyTitleDeedChooseModal.vue";
import ModalError from "../modals/PropertyTitleDeedChooseModalError.vue";

export default {
  props: {
    stripePriceId: String,
  },
  computed: {
    ...mapState("user", ["user"]),
    ...mapState("main", ["currentProperty"]),
  },

  data() {
    return {
      errorModalOpen: false,
      styles: `spinner-btn spinner[data-v-bb7979c4] hover:opacity-80 mr-2`,
      isTitleDeed: false,
      sestava: {},
      isLoading: false,
      lineItems: [
        {
          price: "", // The id of the one-time price you created in your Stripe dashboard
          quantity: 1,
        },
      ],
      // Stripe settings
      pk: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY,
      loading: false,
      successUrl: 
        this.stripePriceId === "price_1IqHTcI4HEbZGFYDomdJNFTK" || this.stripePriceId === "price_1LhURfI4HEbZGFYDgMM7veck" ? 
          `${process.env.VUE_APP_STRIPE_SUCCESS_URL}&service=staging&routePathname=/vyber-terminu-staging` :
        this.stripePriceId === "price_1IqHUCI4HEbZGFYDyOYG7p5H" || this.stripePriceId === "price_1LhUSaI4HEbZGFYD3eQlZEr7" ? 
          `${process.env.VUE_APP_STRIPE_SUCCESS_URL}&service=foceni&routePathname=/vyber-terminu-foceni` :
        this.stripePriceId === "price_1IqHUhI4HEbZGFYDzDcIN2TT" || this.stripePriceId === "price_1LhUTCI4HEbZGFYDzYhdk71n" ? 
          `${process.env.VUE_APP_STRIPE_SUCCESS_URL}&service=video&routePathname=/vyber-terminu-video` :
        this.stripePriceId === "price_1IqHVGI4HEbZGFYDvYeKbkWI" || this.stripePriceId === "price_1LhUXdI4HEbZGFYDokScP8Hq" ? 
          `${process.env.VUE_APP_STRIPE_SUCCESS_URL}&service=model&routePathname=/vyber-terminu-model` :
        this.stripePriceId === "price_1KOewjI4HEbZGFYDiDUOCWB0" || this.stripePriceId === "price_1LhUWVI4HEbZGFYDL11dOZUg" ? 
          `${process.env.VUE_APP_STRIPE_SUCCESS_URL}&service=konzultace&routePathname=/vyber-terminu-konzultace` :
        `${process.env.VUE_APP_STRIPE_SUCCESS_URL}&routePathname=${location.pathname}`,
      cancelUrl: location.href,
      clientReferenceId: "",
      customerEmail: "",
      metadata: {},
    };
  },

  components: {
    ButtonSpinner,
    StripeCheckout,
    Modal,
    ModalError,
  },

  methods: {
    ...mapActions("user", {
      loginStore: LOGIN,
    }),
    async modalOptionHandler(choosedOption) {
      switch (choosedOption) {
        case 0:
          await this.pay("flat");
          break;
        case 1:
          this.pay("house");
          break;
        case 2:
          this.isTitleDeed = false;
          break;
      }
    },
    async getHouse() {},
    async getFlat() {},
    async chooseTitleDeed() {
      this.isTitleDeed = true;
    },
    async pay(lvType) {
      this.isLoading = true;
      if (!this.stripePriceId) {
        return;
      }
      if (
        this.stripePriceId === "price_1IqHIsI4HEbZGFYDbTvrgK5P" ||
        this.stripePriceId === "price_1LBHT4I4HEbZGFYDs3FwKCZ9"
      ) {
        this.sestava = await generateHouseReport(
          JSON.stringify({
            lvType: lvType,
            id: this.currentProperty.id,
            address: this.currentProperty.address,
            city: this.currentProperty.city,
            zipCode: this.currentProperty.zipCode,
            propertyType: lvType === 'flat' ? 1 : 2,
          })
        );
        if (this.sestava === 500) {
          this.isTitleDeed = false;
          this.isLoading = false;
          this.errorModalOpen = true;
        }
        const { pocetStran } = this.sestava;
        if (this.sestava.status === "zpracován") {
          this.$const.TITTLE_DEED.forEach((i) => {
            if (i.idx == pocetStran) {
              this.stripePriceId = i.priceId;
            }
          });
        } else return;
      }
      try {
        this.lineItems[0].price = this.stripePriceId;
        this.$refs.checkoutRef.redirectToCheckout();
        window.localStorage.removeItem("price");
      } catch {
        this.isLoading = false;
      }
    },
  },
  async mounted() {
    this.clientReferenceId = this.currentProperty.id;
    this.customerEmail = this.user.email;
    this.metadata = { id: this.user.id };
  },
};
</script>

<style scoped>
.spinner-btn {
  background-color: #1b26e2 !important;
  color: white !important;
  border-radius: 8px !important;
  height: 50px !important;
  width: 120px !important;
}

.spinner[data-v-bb7979c4] {
  height: 24px !important;
  width: 24px !important;
  margin-right: 15px !important;
  opacity: 1 !important;
  animation: rotation-data-v-bb7979c4 0.7s infinite linear !important;
  border: 4px solid white !important;
  border-top-color: #1b26e2 !important;
  border-radius: 100% !important;
  transition: all 0.3s ease !important;
}
</style>
