<template>
  <div
    class="fixed z-50 inset-0"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
  >
    <div
      class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
    >
      <div
        class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        aria-hidden="true"
      ></div>

      <span
        class="hidden sm:inline-block sm:align-middle sm:h-screen"
        aria-hidden="true"
        >&#8203;</span
      >
      <div
        class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
      >
        <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          <div>
            <p class="font-semibold p-4">
              Jaký typ listu vlastnictví si přejete objednat?
            </p>
            <div class="pl-4 flex">
              <div>
                <Info />
              </div>
              <span
                style="line-height: 24px"
                class="text-gray-500 text-md text-body-1 font-semibold select-none pl-2 pr-8"
                >Pro správnou funkčnost našich dalších služeb, zakupte nejdříve
                list vlastnictví na bytovou jednotku.
              </span>
            </div>

            <div class="text-center sm:text-left pb-2 pt-4">
              <div>
                <button
                  @click="[$emit('chooseType', 0), (typeZero = true)]"
                  type="button"
                  :class="btn"
                >
                  <div v-if="typeZero" class="pr-3">
                    <Loading></Loading>
                  </div>
                  Bytovou jednotku (detail bytové jednotky)
                </button>
              </div>

              <div>
                <button
                  @click="[$emit('chooseType', 1), (typeOne = true)]"
                  type="button"
                  :class="btn"
                >
                  <div v-if="typeOne" class="pr-3">
                    <Loading></Loading>
                  </div>
                  Celý dům s jednotlivými byty
                </button>
                <div
                  v-if="typeOne"
                  style="line-height: 20px; font-size: 14px;"
                  class="text-gray-500 text-md text-body-1 font-semibold select-none pr-8 mt-2 sm:ml-3"
                >
                  Prosíme o strpení. Příprava dat z katastru může trvat déle.
                </div>
              </div>
              <div>
                <button
                  @click="$emit('chooseType', 2)"
                  type="button"
                  class="mt-2 inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-1 bg-red-600 text-base text-white hover:bg-red-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-400 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  Zpět
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Info from "../../components/svg/Info.vue";
import Loading from "../svg/Loading.vue";
export default {
  components: {
    Info,
    Loading,
  },
  data: () => {
    return {
      typeZero: false,
      typeOne: false,
      btn: `mt-2 inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-body-1 text-base font-medium text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm`,
    };
  },
};
</script>
