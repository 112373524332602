<template>
  <div
    class="fixed z-50 inset-0"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
  >
    <div
      class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
    >
      <div
        class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        aria-hidden="true"
      ></div>

      <span
        class="hidden sm:inline-block sm:align-middle sm:h-screen"
        aria-hidden="true"
        >&#8203;</span
      >
      <div
        class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
      >
        <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          <div>
            <p class="font-semibold p-4 text-red-600">
              List vlastnictví nebyl vygenerován!
            </p>

            <div class="text-center sm:text-left pl-4 pb-2">
              <div>Zkuste to později, nebo kontaktujte podporu.</div>
            </div>

            <button
              @click="$emit('close')"
              type="button"
              class="mt-2 inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-1 bg-red-600 text-base text-white hover:bg-red-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-400 sm:ml-3 sm:w-auto sm:text-sm"
            >
              Zpět
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => {
    return {
      typeZero: false,
      typeOne: false,
      btn: `mt-2 inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-body-1 text-base font-medium text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm`,
    };
  },
};
</script>
